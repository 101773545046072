import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/core";

import MainChart from "../mainChart";
import CompanyNews from "./CompanyNews";
import "./company.scss";
import { connect } from "react-redux";
import AISentiment from "./AISentiment";

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.innerBackground,
  },
  headerTitle: {
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
  },
}));

const Company = ({ symbols, breadCrumb }) => {
  const classes = useStyles();
  const history = useHistory();
  const [newsEmpty, setNewsEmpty] = useState(false);

  const goBackToHome = () => {
    history.push(breadCrumb.path);
  };
  const notifyNewsStatus = (flag) => {
    setNewsEmpty(flag);
  }

  const isForexPage = useMemo(() => {
    if ((symbols && symbols.value && symbols.value.includes('/')) || (symbols && symbols.value && symbols.value.includes('-'))) {
      return true;
    } else {
      return false;
    }
  }, [symbols]);

  return (
    <div className={classes.background + ' company-page'}>
      <div className="company-nav-header">
        <div className="company-nav-header-back-button" onClick={goBackToHome}>
          <LeftOutlined /> {breadCrumb.name}
        </div>
        <div className={classes.headerTitle}>
          {symbols?.value?.replace("-", "/")}
        </div>
      </div>
      <div className="company-container">
        <div className={`full-width flex-start${isForexPage ? '' : ' non-forex-page'}`}>
          <div className={`company-chart-wrapper`}><MainChart /></div>
          {
            isForexPage && (
              <div className={`company-news-wrapper${newsEmpty ? ' ai-sentiment-only' : ''}`}>
                <CompanyNews notifyNewsStatus={notifyNewsStatus} />
                <AISentiment ticker={symbols.value.replace("/", "-")} />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  symbols: state.symbol.symbol,
  breadCrumb: state.breadCrumb.breadCrumb
});
export default connect(stateToProps, null)(Company);
