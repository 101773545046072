import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const FOREX_ACTIVITY_LOAD = "FOREX/LOAD/TOP";
export const FOREX_ACTIVITY_LOAD_SUCCESS = "FOREX/LOAD/TOP/SUCCESS";
export const FOREX_ACTIVITY_LOAD_FAILURE = "FOREX/LOAD/TOP/FAILURE";

// Action Creators
export const forexActivityLoad = (pageSize, firstLoad) => ({
  type: FOREX_ACTIVITY_LOAD,
  pageSize,
  firstLoad
});
export const forexActivityLoadSuccess = (data, loading) => ({
  type: FOREX_ACTIVITY_LOAD_SUCCESS,
  data,
  loading,
});
export const forexActivityLoadFailure = (error) => ({
  type: FOREX_ACTIVITY_LOAD_FAILURE,
  error,
  loading: false,
});

// Sagas
function* fetchForexActivity(action) {
  try {
    const { data, pageNumber } = yield select((state) => state.forexActivity);

    const forexActivityState = yield select((state) => state.forexActivity);
    yield put(forexActivityLoadSuccess({ ...forexActivityState, data, loading: true }));

    const { pageSize, firstLoad } = action;

    let nextPageNumber;

    if (firstLoad) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = pageNumber + 1;
    }

    const response = yield axios.get(`${apiURL}/forex/unusual-activity/${nextPageNumber}/${pageSize}`, { withCredentials: true });

    let updatedData = [];
    if (firstLoad) {
      updatedData = [...response.data.results]
    } else {
      updatedData = [...data.concat(response.data.results)]
    }

    updatedData = [
      ...new Map(updatedData.map((item, index) => [index, item])).values(),
    ];

    yield put(
      forexActivityLoadSuccess({
        data: updatedData,
        loading: false,
        pageNumber: nextPageNumber,
      })
    );
  } catch (error) {
    yield put(forexActivityLoadFailure(error));
  }
}

function* listenForexActivityLoad() {
  yield takeEvery(FOREX_ACTIVITY_LOAD, fetchForexActivity);
}

// Root Saga
export function* saga() {
  yield all([fork(listenForexActivityLoad)]);
}

const INIT_STATE = {
  data: [],
  forexActivityLoading: false,
  pageNumber: 0
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FOREX_ACTIVITY_LOAD_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        forexActivityLoading: action.data.loading,
        pageNumber: action.data.pageNumber
      };
    case FOREX_ACTIVITY_LOAD_FAILURE:
      return {
        ...state,
        data: [],
        forexActivityLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
