import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { RiNewspaperLine } from "react-icons/ri";
import { makeStyles } from "@material-ui/core";

import { companyNewsLoad } from "../../appRedux/ducks/companyNews";
import { timeDifference } from "../utilities/date";

import "./company-news-style.scss";

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.innerBackground,
  },
  text: {
    color: theme.palette.primary.text + ' !important',
  },
}));

const CompanyNews = (props) => {
  const classes = useStyles();
  const { symbol } = useParams();

  const { fetchCompanyNews, loading, companyNews, notifyNewsStatus } = props;

  useEffect(() => {
    if (symbol) {
      fetchCompanyNews(symbol);
    }
  }, [fetchCompanyNews, symbol]);

  useEffect(() => {
    notifyNewsStatus(companyNews.length === 0);
  }, [companyNews])

  return (
    <>
      {
        loading ? (
          <div className="company-news-content-result">
            <Spinner animation="border" role="status" size={20}>
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          companyNews.length > 0 && (
            <div className={'company-news-page'}>
              <div className={classes.text + ' company-news-title'}>News</div>
              <div className="company-news-content">
                {
                  companyNews.map((item, key) => (
                    <div className="company-news-item" key={key}>
                      <div className="company-news-item-big-title">
                        {item.title}
                      </div>

                      <div className="company-news-item-title">
                        <span className="company-news-item-title-icon">
                          <RiNewspaperLine />
                        </span>
                        <span className="company-news-item-title-time">{timeDifference(item.source_date)} &#8226;</span>
                        <span className="company-news-item-title-content">{item.source_name}</span>
                      </div>
                      <div className="company-news-item-content">
                        {item.text}
                      </div>
                      <div className="company-news-item-read-more" onClick={() => window.open(item.news_url, `_blank`)}>Read more</div>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        )
      }
    </>
  );
};


const stateToProps = (state) => ({
  companyNews: state.companyNews.companyNews,
  loading: state.companyNews.companyNewsLoading,
});

const dispatchToProps = (dispatch) => ({
  fetchCompanyNews: (symbol) => dispatch(companyNewsLoad(symbol)),
});

export default connect(stateToProps, dispatchToProps)(CompanyNews);
