/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Company from "./Company";
import "./index.scss";
import "./Equity.scss";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { makeStyles } from "@material-ui/core";
import { companyDataLoad } from "../../appRedux/ducks/companyData";
import clsx from "clsx";
import LiveOptions from "../LiveOptions";
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.tableBackground,
    "& .card": {
      border: "none !important",
      borderRadius: "24px",
    },
  },
  bottom: {
    backgroundColor: theme.palette.primary.tableBackground,
    color: theme.palette.primary.text,
  },
  description: {
    color: theme.palette.primary.description,
  },
  text: {
    color: theme.palette.primary.text + ' !important',
  },
}));
const SecurityContainer = (props) => {
  const classes = useStyles();
  const { symbols, setSymbol, tickerDetails, fetchCompanyData, themeMode } = props;
  const { symbol } = useParams();
  useEffect(() => {
    // fetchCompanyData(symbols.value)
  }, [symbols.value]);
  const symbolMemo = useMemo(() => {
    return !!symbols.value.length ? symbols.value : symbol;
  }, [symbols.value]);
  const [store, setStore] = useState({
    company: {},
    mutualFund: {},
    etf: {},
    meta: {},
    security: {},
    securityMetrics: [],
    securityPrice: {},
    securitySnapshots: [],
    news: [],
    securityLoading: undefined,
    secLoading: undefined,
    securityType: "",
  });
  useEffect(() => {
    setSymbol({
      type: "company",
      value: symbol.replace(/-/g, '/'),
    });
  }, []);

  return (
    <div className="security-page main-chart-container">
      <div className={classes.text + ' component-section-title'}>Chart</div>
      <div className={"col-12"}>
        <Company
          ticker={symbolMemo}
          company={store.company}
          securityMetrics={store.securityMetrics}
          securityPrice={props.securityPrice}
          securitySnapshots={store.securitySnapshots}
          news={store.news}
          currentMarketStatus={props.marketStatus}
        />
      </div>
      <div className="col-12">
        <LiveOptions company={true} />
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  securityPrice: state.companyData.companyData.secPrice,
  marketStatus: state.companyData.companyData.marketState,
  symbols: state.symbol.symbol,
  tickerDetails: state.companyData.companyData.tickerDetails,
  themeMode: state.themeMode.themeMode
});

const dispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  fetchCompanyData: (symbol) => dispatch(companyDataLoad(symbol)),
});

export default connect(stateToProps, dispatchToProps)(SecurityContainer);
