import React, { useEffect } from "react";
import { connect } from "react-redux";

import Gauge from '../common/Gauge';
import { aiSentimentLoad } from "../../appRedux/ducks/aiSentiment";

import "./ai-sentiment.scss";

const AISentiment = ({ ticker, fetchAISentiment, loading, sentiment }) => {
  const [active, setActive] = React.useState('4h');

  useEffect(() => {
    if (ticker) {
      fetchAISentiment(ticker, active);
    }
  }, [ticker, active, fetchAISentiment]);

  return (
    <div className="ai-sentiment-page">
      <div className="ai-sentiment-title">AI Sentiment</div>
      <div className="ai-sentiment-time-slots">
        <div className="ai-sentiment-time-slots-wrapper">
          <div className={`ai-sentiment-time-slot${active === '1h' ? ' active' : ''}`} onClick={() => setActive('1h')}>1 Hr</div>
          <div className={`ai-sentiment-time-slot${active === '4h' ? ' active' : ''}`} onClick={() => setActive('4h')}>4 Hrs</div>
          <div className={`ai-sentiment-time-slot${active === '1d' ? ' active' : ''}`} onClick={() => setActive('1d')}>Daily</div>
          <div className={`ai-sentiment-time-slot${active === '1w' ? ' active' : ''}`} onClick={() => setActive('1w')}>Weekly</div>
        </div>
      </div>

      <div className="meter-chart">
        <Gauge sentiment={sentiment} />
      </div>

    </div>
  );
};

const stateToProps = (state) => ({
  sentiment: state.aiSentiment.aiSentiment,
  loading: state.dashboard.dashboardLoading,
});

const dispatchToProps = (dispatch) => ({
  fetchAISentiment: (ticker, hours) => dispatch(aiSentimentLoad(ticker, hours)),
});

export default connect(stateToProps, dispatchToProps)(AISentiment);
