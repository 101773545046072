import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { makeStyles } from "@material-ui/core";

import InfoModal from "./infoModal/InfoModal";
import "./main.css";
import { CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useAuth } from "../../contexts/Auth";
import { connect } from "react-redux";
import { calendarLoad } from "../../appRedux/ducks/calendar";

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.innerBackground,
    position: "relative",
  },
  headerTitle: {
    padding: 16,
    color: theme.palette.primary.text + ' !important',
    fontFamily: "'Circular Std'!important",
    fontStyle: "normal!important",
    fontWeight: "700!important",
    fontSize: "32px!important",
    lineHeight: "40px!important",
    letterSpacing: "-0.04em!important",
    marginBottom: "16px!important",
  },
}));

const Calendar = ({ loading, calendar, fetchCalendarData, timezone }) => {
  const classes = useStyles();
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const { startingDate, endingDate, setStartingDate, setEndingDate } =
    useAuth();

  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const calendarRef = useRef();

  useEffect(() => {
    fetchCalendarData(startingDate, endingDate);
  }, [startingDate, endingDate]);


  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }
  function handleWeekendsToggle() {
    setWeekendsVisible(!weekendsVisible);
  }
  function handleDateSelect(selectInfo) {
    // console.log("Clicked", selectInfo);
    // console.log("Show modal is", showModal);
    setSelectedDate(selectInfo.start.toDateString());
    handleOpenModal();
  }
  function handleCellRender(eventInfo) {
    var days = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
    const convertedTooltipDate = moment(eventInfo.date.toDateString()).format(
      "dddd MMMM DD YYYY"
    );
    const convertedDateForDataFetching = moment(
      eventInfo.date.toDateString()
    ).format("YYYY-MM-DD");
    // console.log(
    //   "Coverted date for data fetching",
    //   convertedDateForDataFetching
    // );
    if (
      calendar !== null &&
      calendar[convertedDateForDataFetching] &&
      calendar[convertedDateForDataFetching].length
    ) {
      return (
        <InfoModal
          timezone={timezone}
          date={convertedTooltipDate}
          data={calendar[convertedDateForDataFetching]}
          fetching={loading}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <p
                style={{
                  fontFamily: "'Circular Std'",
                  fontStyle: "normal",
                  fontWeight: "450",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#FFFFFF",
                  margin: 0,
                  padding: 0,
                }}
              >
                {eventInfo.date
                  .toLocaleString("en-us", { weekday: "long" })
                  .slice(0, 3)}
              </p>
              <p style={{ margin: 0, padding: 0 }}>{eventInfo.dayNumberText}</p>
            </div>
            {calendar[convertedDateForDataFetching] !== undefined &&
              calendar[convertedDateForDataFetching].length > 0 && (
                <div
                  style={{
                    background: "#00DC0A",
                    padding: "4px",
                    minWidth: "42px",
                    minHeight: "42px",
                    fontFamily: "'Circular Std'",
                    fontStyle: "normal",
                    fontWeight: "450",
                    fontSize: "18px",
                    lineHeight: "23px",
                    color: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* {(() => {
                var min = Math.ceil(0);
                var max = Math.floor(100);
                return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
              })()} */}
                  {calendar[convertedDateForDataFetching].length}
                </div>
              )}
          </div>
        </InfoModal>
      );
    } else if (calendar == null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <p
              style={{
                fontFamily: "'Circular Std'",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#FFFFFF",
                margin: 0,
                padding: 0,
              }}
            >
              {eventInfo.date
                .toLocaleString("en-us", { weekday: "long" })
                .slice(0, 3)}
            </p>
            <p style={{ margin: 0, padding: 0 }}>{eventInfo.dayNumberText}</p>
          </div>

          <div
            style={{
              background: " #00DC0A",
              padding: "4px",
              minWidth: "42px",
              minHeight: "42px",
              fontFamily: "'Circular Std'",
              fontStyle: "normal",
              fontWeight: "450",
              fontSize: "18px",
              lineHeight: "23px",
              color: "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* {(() => {
              var min = Math.ceil(0);
              var max = Math.floor(100);
              return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
            })()} */}
            {/* {monthDatamonthData[convertedDateForDataFetching] !== null
              ? monthDatamonthData[convertedDateForDataFetching].length
              : "null"} */}
            <CircularProgress
              sx={{ color: "#fff", fontSize: "10px", height: "5px" }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <p
              style={{
                fontFamily: "'Circular Std'",
                fontStyle: "normal",
                fontWeight: "450",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#FFFFFF",
                margin: 0,
                padding: 0,
              }}
            >
              {eventInfo.date
                .toLocaleString("en-us", { weekday: "long" })
                .slice(0, 3)}
            </p>
            <p style={{ margin: 0, padding: 0 }}>{eventInfo.dayNumberText}</p>
          </div>
        </div>
      );
    }
  }

  function handleEventClick(clickInfo) {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove();
    }
  }
  function handleEvents(events) {
    console.log("Event", events);
    setCurrentEvents(events);
  }
  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }
  return (
    <div
      className={classes.background}
    >
      <Typography
        className={classes.headerTitle}
      >
        Calendar
      </Typography>
      <div className="demo-app-main">
        <FullCalendar
          showNonCurrentDates={false}
          ref={calendarRef}
          datesSet={(rangeInfo) => {
            setCurrentDate(rangeInfo.startStr);
            setStartingDate(moment(rangeInfo.startStr).format("YYYY-MM-DD"));
            setEndingDate(moment(rangeInfo.endStr).format("YYYY-MM-DD"));
          }}
          customButtons={{
            customPrevMonthBtn: {
              text: "<",
              click: function (e) {
                let calendarApi = calendarRef.current.getApi();
                calendarApi.prev();
              },
            },
            customCurrentMonth: {
              text: moment(currentDate).format("MMMM YY"),
              click: function (e) {
                let calendarApi = calendarRef.current.getApi();
                // calendarApi.prev();
              },
            },
            customNextMonthBtn: {
              text: ">",
              click: function (e) {
                let calendarApi = calendarRef.current.getApi();
                calendarApi.next();
              },
            },
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "customPrevMonthBtn,customCurrentMonth,customNextMonthBtn",
            // left: "dayGridMonth,timeGridWeek customPrevMonthBtn,customCurrentMonth,customNextMonthBtn",
            center: "",
            right: "",
          }}
          initialView="dayGridMonth"
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          // initialEvents={INITIAL_EVENTS}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          // dayCellContent={(event)=>{event.date.toLocaleString}}
          dayCellContent={handleCellRender}
        // contentHeight="600px"
        /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
          */
        />
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  calendar: state.calendar.calendar,
  loading: state.calendar.calendarLoading,
  timezone: state.userSettings.userSettings.timezone,
});

const dispatchToProps = (dispatch) => ({
  fetchCalendarData: (startingDate, endingDate) =>
    dispatch(calendarLoad(startingDate, endingDate)),
});

export default connect(stateToProps, dispatchToProps)(Calendar);

// export default Calendar;
