import React, { useMemo } from "react";

import "./style.scss";

// If sentiment >= 25, Strong Buy
// If sentiment >= 5, Buy
// If sentiment <= -25, Strong Sell
// If sentiment <= -5, Sell
// Otherwise (between -5 and 5), Neutral

const Gauge = ({ sentiment }) => {
    const percentage = useMemo(() => {
        if (sentiment !== undefined && sentiment !== null) {
            if (sentiment >= 25) return 100;
            if (sentiment < 25 && sentiment >= 5) return 75;
            if (sentiment < 5 && sentiment >= -5) return 50;
            if (sentiment < -5 && sentiment > -25) return 25;
            if (sentiment <= -25) return 0;
        } else {
            return 0;
        }
    }, [sentiment]);

    const value = useMemo(() => {
        if (sentiment !== undefined && sentiment !== null) {
            if (sentiment >= 25) return 'Strong Buy';
            if (sentiment < 25 && sentiment >= 5) return 'Buy';
            if (sentiment < 5 && sentiment >= -5) return 'Neutral';
            if (sentiment < -5 && sentiment > -25) return 'Sell';
            if (sentiment <= -25) return 'Strong Sell';
        } else {
            return 'Strong Sell'
        }

    }, [sentiment]);

    return (
        <div className="gauge-chart-base">
            <span className="gauge-label label0">Strong Sell</span>
            <span className="gauge-label label1">Sell</span>
            <span className="gauge-label label2">Neutral</span>
            <span className="gauge-label label3">Buy</span>
            <span className="gauge-label label4">Strong Buy</span>
            <div className="item">
                <span className="item__chart ">
                    <span className={`active-circle percent-${percentage}`}>
                        <span className="inner-circle">
                        </span>
                    </span>
                    <span className={`arrow percent-${percentage}`} />
                    {/* <img src={NeedleIcon} className={`arrow percent-${percentage}`} alt='needle' /> */}
                </span>

            </div>

            <span className="gauge-value">{value}</span>
        </div>
    );
};


export default Gauge;